
.btn-firstload:hover {
    background-color: white!important;
    color: #01162b!important;
    border: 1px solid #01162b;
}

.btn-custom:hover{
   background-color: #0062cc!important;
   color: #fff!important;
   border-color: #0062cc;
}
.btn-custom{
  background-color: #073262;
}
/* end common button css */
/* first time modal */
/* .alert-box .mat-mdc-dialog-content{
    max-height: inherit;
}

.alert-box .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
  background-color: #073262!important;
  border-color: #073262!important;
  border-radius: 0.25rem;
} */
.text-right{
    text-align: right;
}


/* start loader */
.internal.loader-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.75;
    z-index: 901;
  }
  
  .internal.loader-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 902;
  }

.internal .loader-spinner,
.internal .loader-spinner:before,
.internal .loader-spinner:after {
  background:   #020e1b;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.internal .loader-spinner {
  color: #020e1b;
  text-indent: -9999em;
  /* margin: 88px auto;
  position: relative; */
  font-size: 9px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.internal .loader-spinner:before,
.internal .loader-spinner:after {
  position: absolute;
  top: 0;
  content: "";
}

.internal .loader-spinner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.internal .loader-spinner:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* end loader */

/* .firstTimeComponent .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
  background-color: #073262!important;
} */

.md-toast.success {
  background-color:#28a745;
}
.firstTimeComponent .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size) - 31px) / 2);
  left: calc((var(--mdc-checkbox-state-layer-size) - 29px) / 2);
}
.firstTimeComponent .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-state-layer-size) - 30px) / 2);
  margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
}
.firstTimeComponent .mat-mdc-dialog-content{
  padding:0px!important;
}
.firstTimeComponent .mdc-label .acknowledge::first-line{
  line-height: 100px;
}
.summary-components .mdc-line-ripple,.mat-pseudo-checkbox {
  display: none;
}

/* .summary-components .mat-mdc-form-field-infix {
  min-height: 33px !important;
} */
.summary-components .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  /* padding-top: 5px!important;
  padding-bottom: 0px!important; */
}
.summary-components .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper{
  background-color: #ffffff;
}
.summary-components-class .mdc-list-item__primary-text,
 .summary-components .mat-mdc-select-value{
  font-size: 0.8rem!important;
  height: calc(2.25rem + 2px) !important;
  color: #073262;
}

.summary-components .mat-mdc-option .mat-pseudo-checkbox-minimal,
.summary-components .mat-mdc-form-field-subscript-wrapper,
.summary-components .mat-pseudo-checkbox-checked::after{
  display: none!important;
}
.summary-components .mat-mdc-select-placeholder {
  color: #073262 !important;
}
.summary-components .mat-mdc-form-field{
  width: 100%;
}
.summary-components-class .mat-pseudo-checkbox{
  display: none!important;
}
.summary-components-class .mat-mdc-option-ripple{
  border-bottom: 1px solid #ccc;
}
.mat-mdc-option:last-child .mat-mdc-option-ripple{
  border-bottom: none;
}
.summary-components-class .mat-mdc-option{
min-height: 30px!important;
}
.summary-components-class.mat-mdc-select-panel{
  padding: 0px!important;
}
.mat-select-panel {
 
}
/* .mat-mdc-dialog-container .mdc-dialog__surface,
.mat-mdc-dialog-content{
   background-color: transparent!important; 
  

}
.mat-mdc-dialog-container{
  background-color: #fff;
}
.firstTimeComponent .row{
  background-color: #fff;
} */
.mat-mdc-dialog-container .mdc-dialog__content{
  border-bottom: 1px solid #e9ecef;
}

.document-summarization-layout{
  margin: 0;
  font-family: georgia;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #f5f5f5;
}
 
.document-summarization-layout .content {
  padding: 0 0.5rem;
}
.document-summarization-layout .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
  
.document-summarization-layout .btn-primary {
  color: #fff;
  background-color: #021d44;
  border-color: #021d44;
  box-shadow: none
}

ul {
  list-style-type: square;
}